@font-face {
    font-family: "Ploni Medium";
    src: url("fonts/ploni-medium-aaa.eot");
    src:
        url("fonts/ploni-medium-aaa.eot?#iefix") format("embedded-opentype"),
        url("fonts/ploni-medium-aaa.woff2") format("woff2"),
        url("fonts/ploni-medium-aaa.woff") format("woff");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Ploni Regular";
    src: url("fonts/ploni-regular-aaa.eot");
    src:
        url("fonts/ploni-regular-aaa.eot?#iefix") format("embedded-opentype"),
        url("fonts/ploni-regular-aaa.woff2") format("woff2"),
        url("fonts/ploni-regular-aaa.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Ploni Light";
    src: url("fonts/ploni-light-aaa.eot");
    src:
        url("fonts/ploni-light-aaa.eot?#iefix") format("embedded-opentype"),
        url("fonts/ploni-light-aaa.woff2") format("woff2"),
        url("fonts/ploni-light-aaa.woff") format("woff");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Ploni Demi Bold";
    src: url("fonts/ploni-demibold-aaa.eot");
    src:
        url("fonts/ploni-demibold-aaa.eot?#iefix") format("embedded-opentype"),
        url("fonts/ploni-demibold-aaa.woff2") format("woff2"),
        url("fonts/ploni-demibold-aaa.woff") format("woff");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Ploni Ultra Light";
    src: url("fonts/ploni-ultralight-aaa.eot");
    src:
        url("fonts/ploni-ultralight-aaa.eot?#iefix") format("embedded-opentype"),
        url("fonts/ploni-ultralight-aaa.woff2") format("woff2"),
        url("fonts/ploni-ultralight-aaa.woff") format("woff");
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Ploni Ultra Bold";
    src: url("fonts/ploni-ultrabold-aaa.eot");
    src:
        url("fonts/ploni-ultrabold-aaa.eot?#iefix") format("embedded-opentype"),
        url("fonts/ploni-ultrabold-aaa.woff2") format("woff2"),
        url("fonts/ploni-ultrabold-aaa.woff") format("woff");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
